<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-flex xs13 sm11 md9>
      <v-select
        class="mt-10"
        v-model="value"
        :items="items"
        chips
        label="Выбранные дни"
        multiple
        outlined
        @input="fetchOperators"
        :menu-props="{ minWidth: '250', maxHeight: '600' }"
      />
      <v-data-table
        :headers="showHeaders"
        :items="statistic"
        :search="search"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Статистика</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn v-on:click="fetchOperatorsInfo" elevation="2"
              >Экспорт данных</v-btn
            >
            <v-spacer />
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label="Выбранные колонки"
              multiple
              return-object
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption"
                  >(+{{ selectedHeaders.length - 3 }})</span
                >
              </template>
            </v-select>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    items: [],
    value: [],
    search: "",
    dialog: false,
    headers: [
      { text: "День", value: "date" },
      { text: "Менеджер", value: "operator" },
      { text: "Депы", value: "deposit" },
      { text: "Выводы", value: "withdraw" },
      { text: "Профит", value: "profit" },
      { text: "Игроки", value: "playerCount" },
      { text: "Игроки с депами", value: "playerDepCount" },
      { text: "Отписано", value: "commentCount" },
    ],
    statistic: [],
    selectedHeaders: [],
  }),
  created() {
    this.selectedHeaders = this.headers;
    this.fetchDates().then((dates) => {
      this.items = dates;
      this.value = [dates.at(-1)];
      this.fetchOperators();
    });
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },
  methods: {
    fetchDates: async function () {
      let response = await this.$axios.get("/operator/dates");
      return response.data.dates;
    },
    fetchOperators: async function () {
      let response = await this.$axios.post("/operator/statistic", this.value);
      this.statistic = response.data.map((statistic, id) => ({
        id: id + 1,
        date: statistic.date,
        operator: statistic.operator,
        deposit: Math.round(statistic.deposit * 10) / 10,
        withdraw: Math.round(statistic.withdraw * 10) / 10,
        profit: Math.round((statistic.deposit - statistic.withdraw) * 10) / 10,
        playerCount: statistic.playerCount,
        playerDepCount: statistic.playerDepCount,
        commentCount: statistic.commentCount,
      }));
    },
    fetchOperatorsInfo: async function () {
      const rows = [
        [
          "День",
          "Менеджер",
          "Депы",
          "Выводы",
          "Прибыль",
          "Игроки",
          "Игроки с депами",
          "Отписано",
        ],
      ];

      this.statistic.forEach((info) => {
        let statistic = [
          info.date,
          info.operator,
          info.deposit,
          info.withdraw,
          info.profit,
          info.playerCount,
          info.playerDepCount,
          info.commentCount,
        ];
        rows.push(statistic);
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      const url = encodeURI(csvContent);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "vip_statistic.csv");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
.theme--dark.v-list-item::before {
  opacity: 0;
}
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
</style>
