const state = () => ({
  roles: JSON.parse(localStorage.getItem("roles") || "[]"),
  username: localStorage.getItem("username"),
  token: null,
  exp: null,
  refreshSession: localStorage.getItem("refreshSession"),
  login:
    localStorage.getItem("refreshSession") != null &&
    localStorage.getItem("refreshSession") > new Date().getTime() / 1000,
});

const getters = {
  isAuthenticated: (state) => {
    return state.login;
  },
  isSheetPanel: (state, getters) => {
    return getters.isAuthenticated && state.roles.includes("sheetPanel");
  },
  isFsPromocode: (state, getters) => {
    return getters.isAuthenticated && state.roles.includes("fsPromocode");
  },
  isOperatorPanel: (state, getters) => {
    return getters.isAuthenticated && state.roles.includes("operatorPanel");
  },
  isFailedTransactionPanel: (state, getters) => {
    return (
      getters.isAuthenticated && state.roles.includes("failedTransactionPanel")
    );
  },
  isOperatorStatistics: (state, getters) => {
    return (
      getters.isAuthenticated && state.roles.includes("operatorStatistics")
    );
  },
  isOperatorDailyStatistics: (state, getters) => {
    return (
      getters.isAuthenticated && state.roles.includes("operatorDailyStatistics")
    );
  },
  isFsExcess: (state, getters) => {
    return getters.isAuthenticated && state.roles.includes("fsExcess");
  },
  isFsCompensation: (state, getters) => {
    return getters.isAuthenticated && state.roles.includes("fsCompensation");
  },
};

const actions = {};

const mutations = {
  login: (state, payload) => {
    localStorage.setItem("roles", JSON.stringify(payload.data.roles));
    state.roles = payload.data.roles;
    state.token = payload.data.accessToken;
    state.exp = payload.data.exp;
    state.refreshSession = payload.data.refreshSession;
    localStorage.setItem("refreshSession", payload.data.refreshSession);
    localStorage.setItem("username", payload.login);
    state.username = payload.login;
    state.login = true;
  },
  refreshSession: (state, payload) => {
    state.token = payload.data.accessToken;
    state.exp = payload.data.exp;
    state.refreshSession = payload.data.refreshSession;
    localStorage.setItem("refreshSession", payload.data.refreshSession);
  },
  logout: (state) => {
    localStorage.removeItem("roles");
    state.roles = null;
    state.username = "";
    localStorage.removeItem("username");
    state.token = null;
    state.exp = null;
    state.refreshSession = null;
    localStorage.removeItem("refreshSession");
    state.login = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
