import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UserRegister from "../views/Register";
import Login from "../views/Login";
import Panel from "../views/admin/SheetPanel";
import Promocode from "../views/admin/PromocodePanel";
import OperatorPanel from "../views/operator/OperatorPanel";
import FinancePanel from "../views/finance/FinancePanel";
import OperatorAdminPanel from "../views/operator/AdminPanel";
import OperatorDailyAdminPanel from "../views/operator/AdminDailyPanel";
import ExcessPanel from "../views/provider/ExcessPanel";
import CompensationPanel from "../views/provider/CompensationPanel";
import store from "../store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.getters["user/isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["user/isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};

const ifSheetPanel = (to, from, next) => {
  if (store.getters["user/isSheetPanel"]) {
    next();
    return;
  }
  next("/");
};

const ifFsPromocode = (to, from, next) => {
  if (store.getters["user/isFsPromocode"]) {
    next();
    return;
  }
  next("/");
};

const ifOperatorPanel = (to, from, next) => {
  if (store.getters["user/isOperatorPanel"]) {
    next();
    return;
  }
  next("/");
};

const ifFailedTransactionPanel = (to, from, next) => {
  if (store.getters["user/isFailedTransactionPanel"]) {
    next();
    return;
  }
  next("/");
};

const ifOperatorStatistics = (to, from, next) => {
  if (store.getters["user/isOperatorStatistics"]) {
    next();
    return;
  }
  next("/");
};

const ifOperatorDailyStatistics = (to, from, next) => {
  if (store.getters["user/isOperatorDailyStatistics"]) {
    next();
    return;
  }
  next("/");
};

const ifFsExcess = (to, from, next) => {
  if (store.getters["user/isFsExcess"]) {
    next();
    return;
  }
  next("/");
};

const ifFsCompensation = (to, from, next) => {
  if (store.getters["user/isFsCompensation"]) {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: UserRegister,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  { path: "/user", beforeEnter: ifAuthenticated },
  {
    path: "/admin/panel",
    name: "Panel",
    component: Panel,
    beforeEnter: ifSheetPanel,
  },
  {
    path: "/admin/promocode",
    name: "Promocode",
    component: Promocode,
    beforeEnter: ifFsPromocode,
  },
  {
    path: "/operator/panel",
    name: "OperatorPanel",
    component: OperatorPanel,
    beforeEnter: ifOperatorPanel,
  },
  {
    path: "/finance/panel",
    name: "FinancePanel",
    component: FinancePanel,
    beforeEnter: ifFailedTransactionPanel,
  },
  {
    path: "/operator/statistic",
    name: "OperatorAdminPanel",
    component: OperatorAdminPanel,
    beforeEnter: ifOperatorStatistics,
  },
  {
    path: "/operator/statistic/daily",
    name: "OperatorDailyAdminPanel",
    component: OperatorDailyAdminPanel,
    beforeEnter: ifOperatorDailyStatistics,
  },
  {
    path: "/provider/excess",
    name: "ProviderExcessPanel",
    component: ExcessPanel,
    beforeEnter: ifFsExcess,
  },
  {
    path: "/provider/compensation",
    name: "ProviderCompensationPanel",
    component: CompensationPanel,
    beforeEnter: ifFsCompensation,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
