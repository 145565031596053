<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-flex xs13 sm11 md9>
      <v-select
        class="mt-10"
        v-model="value"
        :items="items"
        chips
        label="Выбранный день"
        outlined
        @input="fetchFsExcess"
        :menu-props="{ minWidth: '250', maxHeight: '600' }"
      />
      <v-data-table
        :headers="showHeaders"
        :items="statistic"
        :search="search"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Лимиты</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn v-on:click="fetchOperatorsInfo" elevation="2"
              >Экспорт данных</v-btn
            >
            <v-spacer />
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label="Выбранные колонки"
              multiple
              return-object
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption"
                  >(+{{ selectedHeaders.length - 3 }})</span
                >
              </template>
            </v-select>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    items: [],
    value: [],
    search: "",
    dialog: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Провайдер", value: "name" },
      { text: "Лимит", value: "excess" },
    ],
    statistic: [],
    selectedHeaders: [],
  }),
  created() {
    this.selectedHeaders = this.headers;
    this.fetchDates().then((dates) => {
      this.items = dates;
      this.value = dates.at(-1);
      this.fetchFsExcess();
    });
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },
  methods: {
    fetchDates: async function () {
      let response = await this.$axios.get("/providers/dates");
      return response.data.dates;
    },
    fetchFsExcess: async function () {
      let response = await this.$axios.post("/providers/excess-statistic", {
        date: this.value,
      });
      this.statistic = response.data.map((provider) => ({
        id: provider.id,
        name: provider.name,
        excess: Math.round(provider.excess * 100) / 100,
      }));
    },
    fetchOperatorsInfo: async function () {
      const rows = [["ID", "Провайдер", "Лимит"]];

      this.statistic.forEach((info) => {
        let statistic = [info.id, info.name, info.excess];
        rows.push(statistic);
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      const url = encodeURI(csvContent);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fs_excess.csv");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
.theme--dark.v-list-item::before {
  opacity: 0;
}
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
</style>
