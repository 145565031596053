<template>
  <v-dialog @click:outside="closeDialog" :value="dialog" max-width="450px">
    <v-card
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-card-text>
        <div>
          <v-row
            class="d-flex flex-column"
            dense
            align="center"
            justify="center"
          >
            <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              mdi-cloud-upload
            </v-icon>
            <p>Drop your file(s) here, or click to select them.</p>
          </v-row>
          <v-form ref="addFileForm" lazy-validation>
            <input
              type="file"
              accept=".csv"
              ref="fileUpload"
              @change="previewFiles"
            />
          </v-form>
        </div>
        <v-virtual-scroll
          v-if="uploadedFiles.length > 0"
          :items="uploadedFiles"
          height="150"
          item-height="50"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                  <span class="ml-3 text--secondary">
                    {{ item.size }} bytes</span
                  >
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click.stop="removeFile(item.name)" icon>
                  <v-icon> mdi-close-circle </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closeDialog" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>

        <v-btn icon @click.stop="submit">
          <v-icon id="upload-button">mdi-upload</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UploadDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dragover: false,
      uploadedFiles: [],
    };
  },

  methods: {
    closeDialog() {
      this.uploadedFiles = [];
      this.$emit("update:dialog", false);
    },

    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );

      if (index > -1) this.uploadedFiles.splice(index, 1);
    },

    previewFiles(e) {
      Array.from(e.target.files).forEach((element) =>
        this.uploadedFiles.push(element)
      );
    },

    onDrop(e) {
      this.dragover = false;
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];

      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.commit(
          "message/error",
          "Только один файл может загружаться одновременно"
        );
      } else
        Array.from(e.dataTransfer.files).forEach((element) => {
          if (element.type === "text/csv") {
            this.uploadedFiles.push(element);
          } else {
            this.$store.commit("message/error", "Загрузите csv файл");
          }
        });
    },

    submit() {
      if (!this.uploadedFiles.length > 0) {
        this.$store.commit("message/error", "Нет файлов для загрузки");
      } else {
        this.$refs.addFileForm.reset();
        this.$refs.fileUpload.value = null;
        this.$emit("filesUploaded", this.uploadedFiles);
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
