<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-col align="center">
      <v-flex xs13 sm8 md3>
        <v-col align="center">
          <v-toolbar-title
            >Депы:
            {{
              Math.round(sumField("depositInterval") * 10) / 10
            }}</v-toolbar-title
          >
          <v-toolbar-title
            >Выводы:
            {{
              Math.round(sumField("withdrawInterval") * 10) / 10
            }}</v-toolbar-title
          >
          <v-toolbar-title>Всего игроков: {{ players.length }}</v-toolbar-title>
          <v-toolbar-title>Игроков с депами: {{ sumDeps() }}</v-toolbar-title>
          <v-toolbar-title>Отписано: {{ sumComments() }}</v-toolbar-title>
        </v-col>
        <v-select
          class="mt-10"
          v-model="value"
          :items="items"
          chips
          label="Выбранный день"
          outlined
          @input="fetchPlayers"
          :menu-props="{ minWidth: '250', maxHeight: '600' }"
        />
      </v-flex>
      <v-flex xs13 sm11 md12>
        <v-data-table
          :items-per-page.sync="itemsPerPage"
          fixed-header
          :height="
            itemsPerPage === -1 && items.length > 10 ? 48 * 11 - 1 : undefined
          "
          :headers="showHeaders"
          :items="showPlayers"
          :search="search"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Список игроков</v-toolbar-title>
              <v-spacer />
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="Выбранные колонки"
                multiple
                return-object
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 3 }})</span
                  >
                </template>
              </v-select>
              <v-spacer />
              <v-select
                v-model="selectedColors"
                :items="colorItems"
                label="Выбранные цвета"
                multiple
                return-object
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption"
                    >(+{{ selectedColors.length - 3 }})</span
                  >
                </template>
              </v-select>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
              />

              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ "Редактирование" }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.comment"
                            label="Комментарий"
                          />
                          <v-text-field
                            v-model="editedItem.info"
                            label="Инфо"
                          />
                          <v-select
                            v-model="editedItem.color"
                            :items="colorItems"
                            item-text="text"
                            item-value="value"
                            label="Цвет"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="close">
                      Отменить
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item="i">
            <tr>
              <td :class="i.item.color">
                {{ i.item.playerId }}
              </td>
              <td>{{ i.item.lastName }}</td>
              <td>{{ i.item.name }}</td>
              <td>{{ i.item.email }}</td>
              <td>{{ i.item.phone }}</td>
              <td>{{ i.item.info }}</td>
              <td>{{ i.item.deposit }}</td>
              <td>{{ i.item.withdraw }}</td>
              <td>{{ i.item.depositInterval }}</td>
              <td>{{ i.item.withdrawInterval }}</td>
              <td>{{ i.item.profit }}</td>
              <td>{{ i.item.balance }}</td>
              <td>{{ i.item.currency }}</td>
              <td>{{ i.item.comment }}</td>
              <td>
                <v-icon small class="mr-2" @click="editItem(i.item)">
                  mdi-pencil
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-col>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    colorItems: [
      { text: "Зеленый", value: "green lighten-2" },
      { text: "Желтый", value: "orange lighten-2" },
      { text: "Красный", value: "red lighten-2" },
      { text: "Голубой", value: "blue lighten-2" },
      { text: "Не установлен", value: "" },
    ],
    itemsPerPage: 5,
    items: [],
    value: [],
    search: "",
    dialog: false,
    headers: [
      { text: "ID", value: "playerId" },
      { text: "Фамилия", value: "lastName" },
      { text: "Имя", value: "name" },
      { text: "Почта", value: "email" },
      { text: "Номер", value: "phone" },
      { text: "Инфо", value: "info" },
      { text: "Депы", value: "deposit" },
      { text: "Выводы", value: "withdraw" },
      { text: "Депы 3", value: "depositInterval" },
      { text: "Выводы 3", value: "withdrawInterval" },
      { text: "Профит", value: "profit" },
      { text: "Баланс", value: "balance" },
      { text: "Валюта", value: "currency" },
      { text: "Комментарий", value: "comment" },
      {
        text: "Взаимодействие",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    players: [],
    editedItem: {
      id: "",
      playerId: "",
      lastName: "",
      name: "",
      email: "",
      phone: "",
      info: "",
      deposit: "",
      withdraw: "",
      depositInterval: "",
      withdrawInterval: "",
      profit: "",
      balance: "",
      currency: "",
      comment: "",
      color: "",
    },
    defaultItem: {
      id: "",
      playerId: "",
      lastName: "",
      name: "",
      email: "",
      phone: "",
      info: "",
      deposit: "",
      withdraw: "",
      depositInterval: "",
      withdrawInterval: "",
      profit: "",
      balance: "",
      currency: "",
      comment: "",
      color: "",
    },
    selectedHeaders: [],
    selectedColors: [],
    dep3Sum: 0,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.selectedHeaders = this.headers;
    this.selectedColors = this.colorItems;
    this.fetchDates().then((dates) => {
      this.items = dates;
      this.value = dates.at(-1);
      this.fetchPlayers();
    });
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    showPlayers() {
      return this.players.filter((s) => {
        for (let color of this.selectedColors) {
          if (color.value === s.color) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    fetchDates: async function () {
      let response = await this.$axios.get("/operator/dates");
      return response.data.dates;
    },
    fetchPlayers: async function () {
      let response = await this.$axios.post("/operator/vips", {
        date: this.value,
      });
      this.players = response.data.map((player) => ({
        id: player.id,
        playerId: player.playerId,
        lastName: player.lastName,
        name: player.name,
        email: player.email,
        phone: player.phone,
        info: player.info,
        deposit: Math.round(player.deposit * 10) / 10,
        withdraw: Math.round(player.withdraw * 10) / 10,
        depositInterval: Math.round(player.depositInterval * 10) / 10,
        withdrawInterval: Math.round(player.withdrawInterval * 10) / 10,
        profit: Math.round(player.profit * 10) / 10,
        balance: Math.round(player.balance * 10) / 10,
        currency: player.currency,
        comment: player.comment,
        color: player.color,
      }));
    },
    editItem(item) {
      this.editedIndex = this.players.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save: async function () {
      try {
        await this.$axios.post("/operator/update", {
          id: this.editedItem.id,
          player_id: this.editedItem.playerId,
          comment: this.editedItem.comment,
          info: this.editedItem.info,
          color: this.editedItem.color,
        });
        if (this.editedIndex > -1) {
          Object.assign(this.players[this.editedIndex], this.editedItem);
        } else {
          this.players.push(this.editedItem);
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
      this.close();
    },
    sumField(key) {
      return this.players.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumDeps() {
      return this.players.reduce(
        (a, b) => a + (b["depositInterval"] !== 0 ? 1 : 0),
        0
      );
    },
    sumComments() {
      return this.players.reduce(
        (a, b) => a + (b["comment"] !== "" ? 1 : 0),
        0
      );
    },
  },
};
</script>

<style>
.theme--dark.v-list-item::before {
  opacity: 0;
}
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
</style>
