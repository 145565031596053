<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-flex xs12 sm10 md10>
      <v-card
        :loading="loading"
        style="margin: 1em 20% auto"
        elevation="2"
        outlined
        shaped
      >
        <template slot="progress">
          <v-progress-linear indeterminate />
        </template>
        <v-form ref="form" v-model="valid" @submit.prevent="fetchPromocodes()">
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              <v-layout justify-center=""> Статистика </v-layout>
            </v-list-item-title>
            <v-text-field
              v-model="name"
              :rules="rules.required"
              label="Название"
              clearable
            />
          </v-list-item-content>
          <v-card-actions>
            <v-layout justify-center="">
              <v-btn
                :disabled="!valid || loading"
                depressed
                color="primary"
                @click="fetchPromocodes()"
              >
                Выгрузить
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "PromocodePanel",
  data: () => ({
    valid: true,
    fetched: false,
    loading: false,
    timer: undefined,
    name: "",
    rules: {
      required: [(value) => !!value || "Введите название!"],
    },
  }),
  async created() {
    await this.fetchLoading();
    this.timer = setInterval(() => {
      this.fetchLoading();
    }, 5000);
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    fetchLoading: async function () {
      let response = await this.$axios.get("/admin/promocodes/status");
      let newState = response.data.status;
      if (this.loading && this.loading !== newState && this.fetched) {
        let response = await this.$axios.get("/admin/promocodes/data");
        let data = response.data.data;
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `report-${this.fetched}.csv`);
          document.body.appendChild(link);
          link.click();
          this.fetched = false;
        } else {
          this.$store.commit("message/error", "Не удалось сгенерировать отчет");
        }
      }
      this.loading = newState;
    },
    fetchPromocodes: async function () {
      this.cancelAutoUpdate();
      this.loading = true;
      try {
        let response = await this.$axios.get(`/admin/promocodes/${this.name}`);
        if (response.data.length === 0) {
          this.loading = false;
          this.$store.commit("message/error", "Данный промокод не найден!");
        } else {
          let data = response.data[0];
          await this.$axios.get(
            `/admin/promocodes/statistics?id=${data.id}&name=${data.code}`
          );
          this.fetched = data.code;
        }
      } catch (error) {
        this.fetched = false;
        this.loading = false;
        this.$store.commit("message/error", error.response.data.message);
      }
      this.timer = setInterval(() => {
        this.fetchLoading();
      }, 5000);
    },
  },
};
</script>

<style scoped />
