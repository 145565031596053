<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-flex xs13 sm11 md11>
      <v-select
        class="mt-10"
        v-model="value"
        :items="items"
        chips
        label="Выбранный день"
        outlined
        @input="fetchPlayers"
        :menu-props="{ minWidth: '250', maxHeight: '600' }"
      />
      <v-data-table
        :headers="showHeaders"
        :items="showItems"
        :search="search"
        item-key="transactionId"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Список транзакций</v-toolbar-title>
            <v-spacer />
            <v-checkbox
              v-model="checkbox"
              label="Скрыть обработанных"
              hide-details
            ></v-checkbox>
            <v-spacer />
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label="Выбранные колонки"
              multiple
              return-object
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption"
                  >(+{{ selectedHeaders.length - 3 }})</span
                >
              </template>
            </v-select>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template #[`item.status`]="{ item }">
          <v-select
            v-model="item.status"
            :items="statusItems"
            item-text="text"
            item-value="value"
            @change="changeStatus(item)"
          ></v-select>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    statusItems: [
      { text: "Ожидает", value: "wait" },
      { text: "Обработан", value: "complete" },
    ],
    checkbox: true,
    timer: "",
    items: [],
    value: [],
    search: "",
    headers: [
      { text: "ID", value: "playerId" },
      { text: "Фамилия", value: "lastName" },
      { text: "Имя", value: "name" },
      { text: "Страна", value: "country" },
      { text: "Почта", value: "email" },
      { text: "Время", value: "time" },
      { text: "Сумма", value: "amount" },
      { text: "Валюта", value: "currency" },
      { text: "Платежка", value: "agent" },
      { text: "Статус", value: "status", width: "15%" },
    ],
    transactions: [],
    selectedHeaders: [],
  }),
  created() {
    this.selectedHeaders = this.headers;
    this.fetchDates().then((dates) => {
      this.items = dates;
      this.value = dates.at(-1);
      this.fetchPlayers();
    });
    this.timer = setInterval(() => {
      this.fetchPlayers();
    }, 300000);
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    showItems() {
      if (this.checkbox) {
        return this.transactions.filter((s) => s.status !== "complete");
      } else {
        return this.transactions;
      }
    },
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    fetchDates: async function () {
      let response = await this.$axios.get("/finance/dates");
      return response.data.dates;
    },
    fetchPlayers: async function () {
      let response = await this.$axios.post("/finance/deposits", {
        date: this.value,
      });
      this.transactions = response.data;
    },
    changeStatus: async function (item) {
      try {
        await this.$axios.post(
          `/finance/status/${item.transactionId}/${item.status}`
        );
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style>
.theme--dark.v-list-item::before {
  opacity: 0;
}
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
</style>
