<template>
  <v-container>
    <v-navigation-drawer v-model="drawer" app absolute temporary :width="325">
      <template v-slot:prepend>
        <v-list-item v-if="$store.state.user.login" two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $store.state.user.username
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item @click="$router.push('/')" link>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Главная</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <span v-if="!$store.state.user.login">
          <v-list-item @click="$router.push('/login')" link>
            <v-list-item-action>
              <v-icon>mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Вход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$router.push('/register')" link>
            <v-list-item-action>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Регистрация</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span v-if="$store.state.user.login">
          <v-list-group prepend-icon="mdi-account-multiple" no-action>
            <template v-slot:activator>
              <v-list-item-title>CC-Panel</v-list-item-title>
            </template>
            <v-list-item
              v-if="$store.getters['user/isSheetPanel']"
              link
              @click="$router.push('/admin/panel')"
            >
              <v-list-item-action>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Панель</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isFsPromocode']"
              link
              @click="$router.push('/admin/promocode')"
            >
              <v-list-item-action>
                <v-icon>mdi mdi-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Промокоды</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isOperatorPanel']"
              link
              @click="$router.push('/operator/panel')"
            >
              <v-list-item-action>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Панель оператора</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isFailedTransactionPanel']"
              link
              @click="$router.push('/finance/panel')"
            >
              <v-list-item-action>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Панель транзакций</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isOperatorStatistics']"
              link
              @click="$router.push('/operator/statistic')"
            >
              <v-list-item-action>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Статистика менеджеров</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isOperatorDailyStatistics']"
              link
              @click="$router.push('/operator/statistic/daily')"
            >
              <v-list-item-action>
                <v-icon>mdi-clipboard-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >Ежедневная статистика менеджеров</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isFsExcess']"
              link
              @click="$router.push('/provider/excess')"
            >
              <v-list-item-action>
                <v-icon>mdi mdi-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>FS Лимиты</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['user/isFsCompensation']"
              link
              @click="$router.push('/provider/compensation')"
            >
              <v-list-item-action>
                <v-icon>mdi mdi-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>FS Статистика</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item @click="logout" link>
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <div class="hidden-lg-and-up">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </div>
      <span id="title">
        <v-toolbar-title @click="$router.push('/')">CC-Panel</v-toolbar-title>
      </span>
      <span class="hidden-md-and-down" v-if="$store.state.user.login">
        <span v-if="$store.getters['user/isSheetPanel']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/admin/panel')"
            target="_blank"
          >
            <span class="mr-2">Панель</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isFsPromocode']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/admin/promocode')"
            target="_blank"
          >
            <span class="mr-2">Промокоды</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isOperatorPanel']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/operator/panel')"
            target="_blank"
          >
            <span class="mr-2">Панель оператора</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isFailedTransactionPanel']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/finance/panel')"
            target="_blank"
          >
            <span class="mr-2">Панель транзакций</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isOperatorStatistics']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/operator/statistic')"
            target="_blank"
          >
            <span class="mr-2">Статистика менеджеров</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isOperatorDailyStatistics']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/operator/statistic/daily')"
            target="_blank"
          >
            <span class="mr-2">Ежедневная статистика менеджеров</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isFsExcess']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/provider/excess')"
            target="_blank"
          >
            <span class="mr-2">FS Лимиты</span>
          </v-btn>
        </span>
        <span v-if="$store.getters['user/isFsCompensation']">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/provider/compensation')"
            target="_blank"
          >
            <span class="mr-2">FS Статистика</span>
          </v-btn>
        </span>
      </span>
      <v-spacer />
      <span class="hidden-md-and-down">
        <span v-if="!$store.state.user.login">
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/login')"
            target="_blank"
          >
            <span class="mr-2">Вход</span>
          </v-btn>
          <v-btn
            class="ma-2"
            text
            @click="$router.push('/register')"
            target="_blank"
          >
            <span class="mr-2">Регистрация</span>
          </v-btn>
        </span>
        <span v-else>
          <v-btn text @click="logout" target="_blank">
            <span class="mr-2">Выход</span>
          </v-btn>
        </span>
      </span>
    </v-app-bar>
  </v-container>
</template>

<script>
export default {
  name: "AppBar",
  data: function () {
    return {
      drawer: false,
    };
  },
  methods: {
    logout: async function () {
      await this.$axios.post("/user/auth/logout");
      this.$store.commit("user/logout");
      await this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
#title {
  cursor: pointer;
}
</style>
