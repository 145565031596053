<template>
  <v-layout fluid fill-height align-center justify-center>
    <v-flex xs12 sm10 md10>
      <v-card elevation="2" outlined shaped>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            <v-layout justify-center> Выгрузка не випов </v-layout>
          </v-list-item-title>
          <v-select
            v-model="sheetType"
            :items="sheetTypes"
            item-text="text"
            item-value="value"
            filled
            label="Таблица"
          ></v-select>
        </v-list-item-content>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn @click="uploadDialog = true" depressed color="primary">
              Выгрузить
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card
        :loading="loading"
        style="margin: 1em auto auto"
        elevation="2"
        outlined
        shaped
      >
        <template slot="progress">
          <v-progress-linear indeterminate></v-progress-linear>
        </template>
        <v-form ref="form" v-model="valid" @submit.prevent="loadVips()">
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              <v-layout justify-center> Выгрузка випов </v-layout>
            </v-list-item-title>
            <v-row align="center" justify="center">
              <v-col>
                <v-layout justify-center>
                  <v-select
                    v-model="country"
                    :items="countries"
                    item-text="text"
                    item-value="value"
                    filled
                    label="Страна"
                  ></v-select>
                </v-layout>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="avgBet"
                  :rules="rules.avgBet"
                  label="Средний деп"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-card-actions>
            <v-layout justify-center>
              <v-btn
                :disabled="!valid || loading"
                @click="loadVips()"
                depressed
                color="primary"
              >
                Выгрузить
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card
        :loading="loading"
        style="margin: 1em auto auto"
        elevation="2"
        outlined
        shaped
      >
        <template slot="progress">
          <v-progress-linear indeterminate></v-progress-linear>
        </template>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            <v-layout justify-center> Обновление данных </v-layout>
          </v-list-item-title>
        </v-list-item-content>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="updateOlds()"
                >
                  Старички прозвон
                </v-btn>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="updateRejected()"
                >
                  Отказ/Недозвон
                </v-btn>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="updateFinished()"
                >
                  Прозвон совершен
                </v-btn>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="updatePhones()"
                >
                  Без номера
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card
        :loading="loading"
        style="margin: 1em auto auto"
        elevation="2"
        outlined
        shaped
      >
        <template slot="progress">
          <v-progress-linear indeterminate></v-progress-linear>
        </template>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            <v-layout justify-center> Отчеты </v-layout>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="getDates()"
                  label="Выберите промежуток"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                scrollable
                range
                :first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-list-item-title>
          <v-row align="center" justify="center">
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="createNonVipReport()"
                >
                  Не випы
                </v-btn>
              </v-layout>
            </v-col>
            <v-col>
              <v-layout justify-center>
                <v-btn
                  :disabled="loading"
                  depressed
                  color="primary"
                  @click="createVipReport()"
                >
                  Випы
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-list-item-content>
        <v-card-actions>
          <v-layout justify-center>
            <v-data-table
              style="width: 100%"
              :headers="headers"
              :items="reports"
              item-key="id"
              class="elevation-1"
            ></v-data-table>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
    <Upload
      :dialog.sync="uploadDialog"
      :multiple="true"
      @filesUploaded="processUpload($event)"
    />
  </v-layout>
</template>

<script>
import Upload from "@/components/UploadDialog";
export default {
  name: "SheetPanel",
  components: { Upload },
  data: () => ({
    uploadDialog: false,
    sheetType: 1,
    sheetTypes: [],
    dates: [
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10),
    ],
    menu: false,
    headers: [
      { text: "Тип отчета", value: "name" },
      { text: "Депы", value: "total_amount" },
      { text: "Сумма", value: "total_sum" },
      { text: "Начало", value: "start_date" },
      { text: "Конец", value: "end_date" },
      { text: "Дата создания", value: "created_at" },
    ],
    reports: [],
    avgBet: 500,
    country: 1,
    countries: [
      { text: "Россия", value: 1 },
      { text: "Украина", value: 2 },
      { text: "Казахстан", value: 82 },
      { text: "Беларусь", value: 22 },
    ],
    rules: {
      account: [(v) => !!v || "Необходимо указать кошелек"],
      type: [(v) => !!v || "Необходимо указать тип"],
      avgBet: [
        (v) => !!v || "Необходимо ввести кол-во",
        (v) => v >= 1 || "Кол-во должно быть больше нуля",
      ],
    },
    valid: true,
    loading: false,
    timer: undefined,
  }),
  created() {
    this.fetchLoading().then(() => {
      this.fetchReports();
      this.fetchSheets().then((sheets) => {
        Object.keys(sheets).forEach((key) => {
          this.sheetTypes.push({ text: sheets[key], value: parseInt(key) });
        });
      });
    });
    this.timer = setInterval(() => {
      this.fetchLoading();
    }, 5000);
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    fetchLoading: async function () {
      let response = await this.$axios.get("/admin/tasks/status");
      let newState = response.data.run;
      if (!this.loading && this.loading !== newState) {
        await this.fetchReports();
      }
      this.loading = newState;
    },
    updateOlds: async function () {
      try {
        await this.$axios.put("/admin/sheets/olds");
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    updateRejected: async function () {
      try {
        await this.$axios.put("/admin/sheets/rejected");
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    updateFinished: async function () {
      try {
        await this.$axios.put("/admin/sheets/deposits");
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    updatePhones: async function () {
      try {
        await this.$axios.put("/admin/sheets/phones");
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    loadVips: async function () {
      try {
        await this.$axios.put("/admin/sheets/vips", {
          country: this.country,
          avgBet: this.avgBet,
        });
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    createVipReport: async function () {
      let start = this.dates[0];
      let end = this.dates[1];
      if (start > end) {
        let temp = start;
        start = end;
        end = temp;
      }
      try {
        await this.$axios.post("/admin/reports", {
          start: Math.floor(new Date(start).getTime() / 1000),
          end: Math.floor(new Date(end).getTime() / 1000),
          vip: true,
        });
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    createNonVipReport: async function () {
      let start = this.dates[0];
      let end = this.dates[1];
      if (start > end) {
        let temp = start;
        start = end;
        end = temp;
      }
      try {
        await this.$axios.post("/admin/reports", {
          start: Math.floor(new Date(start).getTime() / 1000),
          end: Math.floor(new Date(end).getTime() / 1000),
          vip: false,
        });
        this.loading = true;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("message/error", error.response.data.message);
        }
      }
    },
    fetchReports: async function () {
      let response = await this.$axios.get("/admin/reports");
      this.reports = response.data;
    },
    getDates() {
      let d1 = this.dates[0];
      let d2 = this.dates[1];
      if (d1 && d2) {
        if (d1 > d2) {
          let temp = d1;
          d1 = d2;
          d2 = temp;
        }
        return d1.replaceAll("-", ".") + " - " + d2.replaceAll("-", ".");
      } else {
        return "";
      }
    },
    fetchSheets: async function () {
      let response = await this.$axios.get("/admin/sheets");
      return response.data;
    },
    processUpload: async function (files) {
      for (const file of files) {
        const result = await new Promise((resolve, reject) => {
          try {
            this.$papa.parse(file, {
              worker: true,
              header: true,
              delimiter: ";",
              complete: function (r) {
                resolve(r.data);
              },
            });
          } catch (e) {
            reject(e);
          }
        });
        const data = [];
        result.forEach((player) => {
          const id = player["ID"];
          if (id) {
            data.push([
              id,
              player["Имя"],
              player["Страна"],
              player["E-mail"],
              player["Телефон"],
              player["Дата рождения"],
              player["Активирован Email?"],
              player["Тел. активирован"],
              player["Делал ставки?"],
              player["Вид бонуса"],
              player["Дата регистрации"],
            ]);
          }
        });
        try {
          await this.$axios.post(`/admin/sheets/${this.sheetType}`, data);
        } catch (error) {
          if (error.response.status === 400) {
            this.$store.commit("message/error", error.response.data.message);
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
