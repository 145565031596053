var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fluid":"","fill-height":"","align-center":"","justify-center":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-flex',{attrs:{"xs13":"","sm11":"","md10":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-toolbar-title',[_vm._v("Кол-во: "+_vm._s(this.sumStatistic.count))]),_c('v-toolbar-title',[_vm._v("Всего, EUR: "+_vm._s(this.sumStatistic.sum))]),_c('v-toolbar-title',[_vm._v("Превышение, EUR: "+_vm._s(this.sumStatistic.excess))]),_c('v-toolbar-title',[_vm._v("К возмещению 15%, EUR: "+_vm._s(this.sumStatistic.compensation))])],1),_c('v-select',{staticClass:"mt-10",attrs:{"items":_vm.items,"chips":"","label":"Выбранный день","outlined":"","menu-props":{ minWidth: '250', maxHeight: '600' }},on:{"input":_vm.fetchFsCompensation},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.showHeaders,"items":_vm.filteredItems,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"extended":"","extension-height":"60"}},[_c('v-toolbar-title',[_c('div',[_vm._v("Фриспины")])]),_c('v-divider',{staticClass:"mx-5",attrs:{"vertical":""}}),_c('v-toolbar-items',[_c('v-row',[_c('v-col',{staticClass:"mt-1"},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":_vm.fetchFsInfo}},[_vm._v("Экспорт данных")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mt-2"},[_c('v-select',{attrs:{"items":_vm.headers,"label":"Выбранные колонки","multiple":"","return-object":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 3)+")")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-checkbox',{attrs:{"label":"Доступно для начисления"},on:{"click":function($event){return _vm.updateStatistic()}},model:{value:(_vm.available),callback:function ($$v) {_vm.available=$$v},expression:"available"}})],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(i){return [_c('tr',[_c('td',[_vm._v(_vm._s(i.item.id))]),_c('td',[_vm._v(_vm._s(i.item.name))]),_c('td',[_vm._v(_vm._s(i.item.profit))]),_c('td',[_vm._v(_vm._s(i.item.count))]),_c('td',[_vm._v(_vm._s(i.item.sum))]),_c('td',[_vm._v(_vm._s(i.item.bonusCap))]),_c('td',[_vm._v(_vm._s(i.item.bonusCapSum))]),_c('td',{class:{
                'red lighten-2': i.item.excess > 0,
              }},[_vm._v(" "+_vm._s(i.item.excess)+" ")]),_c('td',{class:{
                'red lighten-2': i.item.compensation > 0,
              }},[_vm._v(" "+_vm._s(i.item.compensation)+" ")]),_c('td',{class:{
                'green lighten-2': i.item.available > 0,
              }},[_vm._v(" "+_vm._s(i.item.available)+" ")]),_c('td',[_c('v-checkbox',{on:{"click":function($event){return _vm.updateStatistic()}},model:{value:(i.item.filter),callback:function ($$v) {_vm.$set(i.item, "filter", $$v)},expression:"i.item.filter"}})],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }