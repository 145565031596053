import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Fingerprint2 from "fingerprintjs2";
import VuePapaParse from "vue-papa-parse";

Vue.prototype.$fingerprint = Fingerprint2;
Vue.config.productionTip = false;
Vue.use(VuePapaParse);
new Vue({
  router,
  store,
  vuetify: vuetify,
  render: (h) => h(App),
}).$mount("#app");
