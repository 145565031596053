<template>
  <v-layout fluid fill-height="" align-center="" justify-center="">
    <v-col align="center">
      <v-flex xs13 sm11 md10>
        <v-col align="center">
          <v-toolbar-title
            >Кол-во: {{ this.sumStatistic.count }}</v-toolbar-title
          >
          <v-toolbar-title
            >Всего, EUR: {{ this.sumStatistic.sum }}</v-toolbar-title
          >
          <v-toolbar-title
            >Превышение, EUR: {{ this.sumStatistic.excess }}</v-toolbar-title
          >
          <v-toolbar-title
            >К возмещению 15%, EUR:
            {{ this.sumStatistic.compensation }}</v-toolbar-title
          >
        </v-col>
        <v-select
          class="mt-10"
          v-model="value"
          :items="items"
          chips
          label="Выбранный день"
          outlined
          @input="fetchFsCompensation"
          :menu-props="{ minWidth: '250', maxHeight: '600' }"
        />
        <v-data-table
          :headers="showHeaders"
          :items="filteredItems"
          :search="search"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar extended extension-height="60">
              <v-toolbar-title><div>Фриспины</div></v-toolbar-title>
              <v-divider class="mx-5" vertical></v-divider>
              <v-toolbar-items>
                <v-row>
                  <v-col class="mt-1">
                    <v-btn v-on:click="fetchFsInfo" elevation="2"
                      >Экспорт данных</v-btn
                    >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск"
                      single-line
                      hide-details
                    />
                  </v-col>
                  <v-col class="mt-2">
                    <v-select
                      v-model="selectedHeaders"
                      :items="headers"
                      label="Выбранные колонки"
                      multiple
                      return-object
                      hide-details
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 3">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text caption"
                          >(+{{ selectedHeaders.length - 3 }})</span
                        >
                      </template>
                    </v-select>
                    <v-checkbox
                      label="Доступно для начисления"
                      v-model="available"
                      @click="updateStatistic()"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
          </template>
          <template v-slot:item="i">
            <tr>
              <td>{{ i.item.id }}</td>
              <td>{{ i.item.name }}</td>
              <td>{{ i.item.profit }}</td>
              <td>{{ i.item.count }}</td>
              <td>{{ i.item.sum }}</td>
              <td>{{ i.item.bonusCap }}</td>
              <td>{{ i.item.bonusCapSum }}</td>
              <td
                :class="{
                  'red lighten-2': i.item.excess > 0,
                }"
              >
                {{ i.item.excess }}
              </td>
              <td
                :class="{
                  'red lighten-2': i.item.compensation > 0,
                }"
              >
                {{ i.item.compensation }}
              </td>
              <td
                :class="{
                  'green lighten-2': i.item.available > 0,
                }"
              >
                {{ i.item.available }}
              </td>
              <td>
                <v-checkbox
                  v-model="i.item.filter"
                  @click="updateStatistic()"
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-col>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    filter: [],
    available: false,
    sumStatistic: {},
    items: [],
    value: [],
    search: "",
    dialog: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Провайдер", value: "name" },
      { text: "GGR, EUR", value: "profit" },
      { text: "Кол-во", value: "count" },
      { text: "Всего, EUR", value: "sum" },
      { text: "Bonus cap, %", value: "bonusCap" },
      { text: "Bonus cap, EUR", value: "bonusCapSum" },
      { text: "Превышение, EUR", value: "excess" },
      { text: "К возмещению 15%, EUR", value: "compensation" },
      { text: "Доступно, EUR", value: "available" },
      { text: "Фильтр", value: "filter", width: "15%" },
    ],
    statistic: [],
    selectedHeaders: [],
  }),
  created() {
    this.selectedHeaders = this.headers;
    this.fetchFilter().then((filter) => {
      this.filter = filter;

      this.fetchDates().then((dates) => {
        this.items = dates;
        this.value = dates.at(-1);
        this.fetchFsCompensation();
      });
    });
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    filteredItems() {
      return this.statistic.filter((i) => {
        return (
          !this.available || (this.available && i.available > 0 && !i.filter)
        );
      });
    },
  },
  methods: {
    fetchDates: async function () {
      let response = await this.$axios.get("/providers/dates");
      return response.data.dates;
    },
    fetchFilter: async function () {
      let response = await this.$axios.get("/providers/list");
      return response.data;
    },
    fetchFsCompensation: async function () {
      let response = await this.$axios.post(
        "/providers/compensation-statistic",
        {
          date: this.value,
        }
      );
      this.statistic = response.data.map((provider) => {
        const excess = Math.round(provider.excess * 100) / 100;
        const available = -excess;
        return {
          id: provider.id,
          name: provider.name,
          profit: Math.round(provider.profit * 100) / 100,
          count: provider.count,
          sum: Math.round(provider.sum * 100) / 100,
          bonusCap: provider.bonusCap,
          bonusCapSum: Math.max(
            0,
            Math.round(provider.bonusCapSum * 100) / 100
          ),
          excess: Math.max(0, excess),
          compensation: Math.round(provider.compensation * 100) / 100,
          available: Math.max(0, available),
          filter: !this.filter.includes(provider.id),
        };
      });
      await this.updateStatistic();
    },
    fetchFsInfo: async function () {
      let rows = this.statistic.reduce(
        (r, info) => {
          r[
            info.excess > 0 && info.filter === false
              ? "availableData"
              : "notAvailableData"
          ].push([
            info.id,
            info.name,
            info.profit,
            info.count,
            info.sum,
            info.bonusCap,
            info.bonusCapSum,
            info.excess,
            info.compensation,
          ]);
          return r;
        },
        {
          availableData: [
            [
              "ID",
              "Провайдер",
              '"GGR, EUR"',
              "Кол-во",
              '"Всего, EUR"',
              '"Bonus cap, %"',
              '"Bonus cap, EUR"',
              '"Превышение, EUR"',
              '"К возмещению 15%, EUR"',
            ],
          ],
          notAvailableData: [],
        }
      );
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows["availableData"].map((e) => e.join(",")).join("\n") +
        "\n,,," +
        this.sumStatistic.count +
        "," +
        this.sumStatistic.sum +
        ",,," +
        this.sumStatistic.excess +
        "," +
        this.sumStatistic.compensation +
        "\n" +
        rows["notAvailableData"].map((e) => e.join(",")).join("\n");
      const url = encodeURI(csvContent);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fs_statistic.csv");
      document.body.appendChild(link);
      link.click();
    },
    sumField(key) {
      return this.statistic.reduce((a, b) => {
        let value;
        if (b["excess"] === 0 || b["filter"] === true) {
          value = 0;
        } else {
          value = b[key];
        }
        return a + value;
      }, 0);
    },
    updateStatistic() {
      this.sumStatistic = {
        count: Math.round(this.sumField("count") * 100) / 100,
        sum: Math.round(this.sumField("sum") * 100) / 100,
        excess: Math.round(this.sumField("excess") * 100) / 100,
        compensation: Math.round(this.sumField("compensation") * 100) / 100,
      };
    },
  },
};
</script>

<style>
.theme--dark.v-list-item::before {
  opacity: 0;
}
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
</style>
